import React from "react";
import dbfooter from './footer.module.css';
import linkedinImg from '../../Images/Social/linkedin.png';
import xImg from '../../Images/Social/twitter.png';
import igImg from '../../Images/Social/instagram.png';
import fbImg from '../../Images/Social/facebook.png';
import { productLinks } from "../../App";
import dablerlogo from "../../../CommonImages/dabler5.png";
import { socialLinks } from "../../../../App";

export default function Footer(props){
  
    return (
        <> 
          <div className={dbfooter["footer-top"]}>
             <div className={dbfooter["company-details"]}>
                <div className={dbfooter["about-company"]}>
                  <img src={dablerlogo} alt="dabler" />
                </div>
                <div className={dbfooter["company-add"]}>
                  <h4>Address</h4>
                  <p>128 City Road, London,<br/> United Kingdom, EC1V 2NX</p>
                </div>
                <div className={dbfooter["company-email"]}>
                  <div className={dbfooter["email-texts"]}>
                    <h4>Email</h4>
                    <p>dabler@dabler.co.uk</p>
                  </div>
                </div>
             </div>

             <div className={`${dbfooter["about-products"]} ${dbfooter["db-f-head-pad"]}`}>
                <div className={dbfooter["footer-col-head"]}>PRODUCTS</div>
                <a href={"/datakob"}>DataKob</a>
                <a href={"/snippetflow"}>SnippetFlow</a>
                <a href={"/acuber"}>Acuber AI</a>
             </div>

             <div className={`${dbfooter["social-media"]} ${dbfooter["db-f-head-pad"]}`}>
                <div className={dbfooter["footer-col-head"]}>Social Media</div>
                <div className={dbfooter["social-container"]}>
                  <a href={socialLinks.linkedIn}><img src={linkedinImg} alt="linkedIn" className={dbfooter["social-icon"]}/></a>
                  {/* <a href={socialLinks.x}><img src={xImg} alt="x" className={dbfooter["social-icon"]}/></a> */}
                  <a href={socialLinks.fb}><img src={fbImg} alt="fb" className={dbfooter["social-icon"]}/></a>
                  {/* <a href={socialLinks.ig}><img src={igImg} alt="ig" className={dbfooter["social-icon"]}/></a> */}
                </div>
             </div>

             <div className={dbfooter["copyright"]}>
                <p className="mbr-text mb-0 mbr-fonts-style display-7">© Copyright DABLER - All Rights Reserved</p>
             </div>
          </div>
        </>
    )
}