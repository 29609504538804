import React from "react";
import Navbar from '../Navbar/Navbar';
import SliderSection from '../SliderSection/SliderSection';
import Products from "../ProductsComp/Products";
import Footer from "../Footer/Footer";
import ContactUs from '../ContactUs/ContactUs';
import FormContact from "../../../Form/FormContact";

export default function Home(props){

    return (
        <>
         <div className="home-container">
            <Navbar />
            <SliderSection />
            <Products />
            {/* <ContactUs /> */}
            <div style={{position: "relative", top: "86px"}}><FormContact /></div>
            <Footer />
         </div>
        </>
    )
}