import React from "react";
import  './footer.css';
import linkedinImg from '../../Images/SocialMedia/linkedin.png';
import xImg from '../../Images/SocialMedia/twitter.png';
import igImg from '../../Images/SocialMedia/instagram.png';
import fbImg from '../../Images/SocialMedia/facebook.png';
import dablerLogo from "../../../CommonImages/dabler4.png";
import { socialLinks } from "../../../../App";
import { Link } from "react-router-dom";

export default function Footer(props){

    return (
        <>
          <div className={"ac-footer-real-top"}>
            <div className={"ac-footer-top"}>
                <div className={"ac-company-details"}>
                    <div className={"ac-about-company"}>
                        <img src={dablerLogo} alt="dabler" />
                    </div>
                    <div className={"ac-company-add"}>
                        <h4>Address</h4>
                        <p>128 City Road, London, <br/>United Kingdom, EC1V 2NX</p>
                    </div> <br/>
                    <div className={"ac-company-email"}>
                        <div className={"ac-email-texts"}>
                            <h4>Email</h4>
                            <p>dabler@dabler.co.uk</p>
                        </div>
                    </div>
                </div>

                <div className={"ac-about-products ira-f-head-pad"}>
                    <div className={"ac-footer-col-head"}>Quick Links</div>
                    {/* <a href="#">Documentation</a>
                    <a href="#">Blogs</a> */}
                    <Link to={'/'}>Dabler</Link>
                    <Link to={'/datakob'}>DataKob</Link>
                    <Link to={'/snippetflow'}>SnippetFlow</Link>
                </div>

                <div className={"ac-social-media ira-f-head-pad"}>
                    <div className={"ac-footer-col-head"}>Social Media</div>
                    <div className={"ac-social-container"}>
                        <a href={socialLinks.linkedIn}><img src={linkedinImg} alt="linkedIn" className={"ac-social-icon"}/></a>
                        {/* <a href={socialLinks.x}><img src={xImg} alt="x" className={"ac-social-icon"}/></a> */}
                        <a href={socialLinks.fb}><img src={fbImg} alt="fb" className={"ac-social-icon"}/></a>
                        {/* <a href={socialLinks.ig}><img src={igImg} alt="ig" className={"ac-social-icon"}/></a> */}
                    </div>
                </div>
            </div>
            
            <div className={"ac-copyright"}>
                <p className="mbr-text mb-0 mbr-fonts-style display-7">© Copyright DABLER - All Rights Reserved</p>
            </div>
          </div>
        </>
    )
}