import React from 'react';
import './bannertwo.css';

export default function BannerTwo(props){ 

    return (
        <>
          <div className="sf-banner2-top-section">
            <div className="sf-banner2-container">
                <h4 className='sf-banner2-heading'>Who Can Benefit from SnippetFlow?</h4>
                <p className="sf-banner2-description"><strong>Data Engineers and Data Scientists:</strong> Those who want to write custom code for
                    acquiring and transforming data or use pre-built pipelines for common datasets. Run
                    Python, Scala, or Java code serverlessly on any cloud provider.
                </p>
                <p className="sf-banner2-description">With SnippetFlow, you get the best of both worlds—flexibility for custom development and
                   pre-built tools for fast execution.
                </p>
            </div>
          </div>
        </>
    )
}