import React from 'react';
import './basiccard.css';
import {basicCardData} from './basicCardData';
import './basiccard.css'; // Import the corresponding CSS file

const BasicCard = () => {

    const result = basicCardData.map(each => {
        return (
          <div className="dc-bc-basic-card">
            <div className="dc-bc-card-image">
              <img src={each.bg} alt={"title"} className="dc-bc-card-img" />
            </div>
            <div className="dc-bc-card-content">
              <h3 className="dc-bc-card-title">{each.title}</h3>
              <p className="dc-bc-card-description">{each.description}</p>
            </div>
            <div className="dc-bc-card-footer">
              <span className="dc-bc-card-footer-text">{""}</span>
            </div>
          </div>
        )
    })
  return (
    <>
     <div className="dc-bc-container">
        <div className='dc-bc-head'>Key Features</div>
        <div className="dc-bc-cards-wrapper">
            {result}
        </div>
     </div>
    </>
  );
};

export default BasicCard;
