import React from 'react';
// import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import Home from './Components/Home/Home';

function App1() {
  console.log(window.innerWidth)
  return (
    <>
      <Home />
    </>
  );
}




export default App1;
