import React from "react";
import './mainabout.css';
import img1 from '../../../Images/about2.jpg';
import img2 from '../../../Images/about1.svg';

export default function MainAbout(){

    return (
        <>
          <div className="main-about-container">
            <div className="main-about-wrapper">
                <div className="main-about-left">
                    <div className="main-about-img-section">
                        <img src={img1} alt="img1" className="about-img1"/>
                        <img src={img2} alt="img2" className="about-img2"/>
                    </div>
                </div>
                <div className="main-about-right"> 
                    <div className="main-about-content-wrapper">
                        <div className="main-about-head-section">
                            <h3 className="main-about-heading">What Is Snippetflow?</h3>
                        </div>
                        <div className="main-about-desc-section">
                            <p className="main-about-description">
                                SnippetFlow is a versatile data orchestration tool designed to handle any data processing
                                task, whether it involves acquiring or transforming data. Developers can write custom code
                                in Python, Scala, Java, or Spark, and deploy tasks as separate Docker jobs on cloud-based,
                                serverless infrastructure. Supporting AWS, Azure, and GCP, SnippetFlow bridges the gap
                                between no-code tools and full orchestration platforms, offering high levels of customization
                                and control.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </>
    )
}