import React from 'react';
import './mainbasic.css';
// import img1 from '../../../Images/MainBasicCardImg/basic-card1.png';
// import img2 from '../../../Images/MainBasicCardImg/basic-card2.png';
// import img3 from '../../../Images/MainBasicCardImg/basic-card3.png';
import codefirst from '../../../Images/MainBasicCardImg/codefirst.png';
import serverless from '../../../Images/MainBasicCardImg/serverless.png';
import infinitescale from '../../../Images/MainBasicCardImg/datascale.png';
import datapipeline from '../../../Images/MainBasicCardImg/datapipeline.png';
import costeffective from '../../../Images/MainBasicCardImg/costeffective.png';
import { motion } from 'framer-motion';

export default function MainBasic(){ 

    const data = [
        {img : codefirst, title : 'Code-First Platform', description : 'Write custom code or use pre-written code for common tasks.'},
        {img : serverless, title : 'Serverless', description : 'No need for backend infrastructure—works seamlessly across cloud providers.'},
        {img : infinitescale, title : 'Infinite Scale', description : 'Capable of handling large datasets, tested with 5TB data loads.'},
        {img : datapipeline, title : 'Data Pipeline Marketplace', description : 'Access ready-to-use pipelines for common datasets with a single click, like retrieving Twitter data by keyword.'},
        {img : costeffective, title : 'Cost Efficiency', description : 'Tasks run 50-60% cheaper compared to competitors.'},

        // {img : '', title : 'Compute', description : 'Automatically deploys serverless compute tasks. No limit on parallelism or compute power.'},
        // {img : '', title : 'Orchestration', description : 'Create pipeline which can run independent compute tasks. Support Python, Scala, Spark, DBT, R, Bash, Java etc.'},
        // {img : '', title : 'Cloud Agnostic', description : 'Deploy to cloud provider of your choice. Deploys tasks on AWS, Azure, GCP etc.'}
    ]

    return (
        <>
          <motion.div className="main-basic-card-container" initial={ {opacity : 0} } animate={{opacity : 1}} transition={{delay : 0.5}}>
            <div className="main-basic-card-wrapper">
           { data.map(each => (
                <div className="main-basic-each-card">
                    <div className="main-basic-img-container"><img src={each.img} alt="img1" className="main-basic-img" /></div>
                    <div className="main-basic-des-container">
                        <h3 className="main-basic-head">{each.title}</h3>
                        <p className="main-basic-text">{each.description}</p>
                    </div>
                </div>
           )) }
             </div>
          </motion.div>
        </>
    )
}