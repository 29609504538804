import contract from "../../Images/BasicCardImages/bc-contract.jpg";
import docs from "../../Images/BasicCardImages/bc-doc.jpg";
import model from "../../Images/BasicCardImages/bc-model.jpg";
import quality from "../../Images/BasicCardImages/bc-quality.jpg";


const basicCardData = [
    {
      bg : model,
      title: "Data Modelling",
      description: "Transform raw data into usable, structured datasets."
    },
    {
      bg : quality,
      title: "Data Validation",
      description: "Ensure that models are error-free and meet requirements."
    },
    {
      bg : docs,
      title: "Data Catalog",
      description: "Explore and understand data stored in your warehouse."
    },
    {
      bg : contract,
      title: "Data Contracts",
      description: "Collaborate on data ownership and manage expectations with version control."
    }
  ];
  
export {basicCardData} ;