import React from "react";
import './splitscreen.css'
import ssImg from '../../Images/PNG/ss_img2.jpg';

export default function SplitScreen(){

    return (
        <>
         <div className="ira-ss-container ira-sect-pad">
            <div className="ira-ss-img-wrapper">
                <img src={ssImg} alt="ss-img" className="ira-ss-img"/>
            </div>
            <div className="ira-ss-text-wrap">
                <div className="ira-ss-text-head">Enhance service operations with fast, accurate, and personalized interactions across channels, driven by our platform's AI capabilities</div>
                <ul className="ira-ss-text-points">
                    <li className="ira-ss-text-point">Support rich, intuitive customer conversations, powered by  various deterministic and generative AI</li>
                    <li className="ira-ss-text-point">One comprehensive development platform for text and voice-based virtual agents</li>
                    <li className="ira-ss-text-point">Compliance and Security, ensuring robust data protection and privacy</li>
                    <li className="ira-ss-text-point">Offers industry-specific templates and pre-designed customer interaction workflows for faster deployment.</li>
                </ul>
            </div>
         </div>
        </>
    )
}