import React from "react";
import './products.css';
import CreativeCards from '../CreativeCards/CreativeCard.jsx';

export default function Products(){

    const data = [
        {name : "ACUBER AI", img : undefined, description : 'Acuber AI is a platform that specializes in creating generative AI-powered chatbots and voice bots for businesses. The platform focuses on delivering personalised customer experiences, improving operational efficiency and driving sales across various industires such as healthcare, retail, travel and more.', link : "/acuber"},
        {name : "DATAKOB", img : undefined, description : 'Datakob is an all-in-one platform that combines Data Modelling, Transformation, and Governance. It helps transform raw data into structured, ready-to-use datasets for data analysts, eliminating the need for complex table joins and transformations within Business Intelligence (BI) tools. With DataKob, users can validate data models to ensure accuracy and compliance with specific requirements, while a rich data catalogue provides insights into the data available in your warehouse.', link : "/datakob"},
        {name : "SNIPPETFLOW", img : undefined, description : 'SnippetFlow is a versatile data orchestration tool designed to handle any data processing task, whether it involves acquiring or transforming data. Developers can write custom code in Python, Scala, Java, or Spark, and deploy tasks as separate Docker jobs on cloud-based, serverless infrastructure. Supporting AWS, Azure, and GCP, SnippetFlow bridges the gap between no-code tools and full orchestration platforms, offering high levels of customization and control.', link : "/snippetflow"},
    ]

    return (
        <>  
          <div className="products-container">
            <div className="products-head-section">
                <h2>Our Products</h2>
            </div>
            <div className="products-content-section">
                {data.map((data, i) => {return <CreativeCards key = {i} data = {data}/>})}
            </div>
          </div>
        </>
    )
}