import React from "react";
import './home.css';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import ContactUsPage from "../ContactUsPage/ContactUspage";
import BasicCard from "../BasicCard/BasicCard";
import SplitScreen from "../SplitScreen/SplitScreen";
import LandingPage from "../LandingPage/LandingPage";
import BannerTwo from "../BannerTwo/BannerTwo";
import FormContact from "../../../Form/FormContact";

export default function Home(props){

    return ( 
        <>
          <div className="home-top">
          <Navbar />
            <LandingPage />
            <SplitScreen />
            <BasicCard />
            <BannerTwo data = {bannerTwoData}/>
            <BannerTwo data = {bannerOneData}/>
            {/* <ContactUsPage /> */}
            <FormContact />
            <Footer />
          </div>
        </>
    )
}


let bannerOneData = {
  title : "Who Can Benefit from DataKob?",
  description :" Data Engineers: Ensure that accurate, high-quality data is delivered, with versioncontrolled agreements on data quality, freshness, and semantics.",
  descriptionTwo : "Data Scientists: Work with reliable, validated data when building AI models.",
  descriptionFinal : "Datakob empowers data teams to transform and govern data efficiently and collaboratively",
  bg : "dark" // options include "dark" or "light" section.
}

let bannerTwoData = {
  title : "Why Choose DataKob?",
  description :" DataKob simplifies data workflows by integrating data modeling, pipelining, validation, and observability into one platform. Its intuitive interface supports Python, SQL, and Scala for modeling, and it eliminates the need for additional orchestration tools or base coding, streamlining the data engineering process.",
  descriptionTwo : "Data Contracts in DataKob operate like GitHub for data, allowing teams to collaborate over data management with version control and enforced ownership, ensuring that any changes in data are properly communicated and managed.",
  descriptionFinal : "",
  bg : "light" // options include "dark" or "light" section.
}