import React from 'react';
import './main.css';
import MainIntroSection from './MainIntroSection/MainIntroSection';
import AboutProduct from '../AboutProduct/AboutProduct';
import MainAbout from './MainAbout/MainAbout';
import MainBasic from './MainBasic/MainBasic';
import MainContactUs from './MainContactus/MainContactUs';
import BannerTwo from '../BannerTwo/BannerTwo';

export default function Main(props){
    

    return (
        <>
          <div className="main-top-section">
            <div className="main-container">
                <MainIntroSection />
                <MainAbout />
                <MainBasic />
                <AboutProduct />
                <BannerTwo />
                {/* <MainContactUs /> */}
            </div>
          </div>
        </>
    )
}