import React from "react";
import './main.css';
import FormContact from "../../../Form/FormContact";

export default function Main(props){

    return (
        <>
          <div className="ira-main-container">
            <div className="ira-main-wrapper">
              <h1>ACUBER AI</h1>
              <div>AI Powered Application</div>
              <p>
                Acuber AI is a platform that specializes in creating generative AI-powered chatbots and voice bots
                for businesses. The platform focuses on delivering personalised customer experiences, improving 
                operational efficiency and driving sales across various industires such as healthcare, retail,
                travel and more.
              </p>
            </div>
          </div>
        </>
    )
}