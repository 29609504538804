import React from "react";
import Navbar from "./Components/Navbar/Navbar";
import Main from "./Components/Main/Main";
import Intro from "./Components/Introduction/Intro";
import IraCard from "./Components/Cards/IraCard";
import Footer from "./Components/Footer/Footer";
import SplitScreen from "./Components/Splitscreen/SplitScreen";
import FormContact from "../Form/FormContact";

export default function Ira(props){

    return (
        <>
          <div className="ira-container">
            <Navbar />
            <Main />
            <SplitScreen />
            {/* <Intro /> */}
            <IraCard />
            <FormContact about="Acuber AI"/>
            <Footer />
          </div>
        </>
    )
}