import React from 'react';
import './splitscreen.css'; // Assuming your styles are in App.css
import img from "../../Images/splitscreen.jpg";

const SplitScreen = () => { 

  return (
    <div className="dc-split-container">
      <div className="dc-split-text">
        <h2 className="dc-split-head">What is DataKob?</h2> 
        <p  className="dc-split-desc">
          DataKob is an all-in-one platform that combines Data Modelling, Transformation, and
          Governance. It helps transform raw data into structured, ready-to-use datasets for data
          analysts, eliminating the need for complex table joins and transformations within Business
          Intelligence (BI) tools. With DataCob, users can validate data models to ensure accuracy
          and compliance with specific requirements, while a rich data catalogue provides insights into
          the data available in your warehouse.
        </p> 
      </div>
      <div className="dc-split-image">
        <img src={img ? img : "https://via.placeholder.com/300"} alt="Placeholder" />
      </div>
    </div>
  );
};

export default SplitScreen;
