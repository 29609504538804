import React from 'react'
import "./blogs.css"
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import pdfImg from '../../Images/Blogs/pdflogo.png';
import pdfFile from '../../../Files/datacontracts.pdf'

const Blogs = () => {
  return (
    <>
     <Navbar />
     <div className="db-blogs-articles-sect">
        <div className="db-blogs-articles-wrap">
          <div className='db-blogs-articles-head'>Blogs and Articles</div>
            <div className="db-blogs-sect">

              <div className="db-blog-wrap" >
                <img src={pdfImg} alt="pdf" />
                <div className="db-blog-head">Data Contracts</div>
                <a href={pdfFile} download style={{paddingLeft : "0.8rem"}}>
                  <button className='db-blog-download-btn' style={{padding: "0.4rem 0.8rem", cursor:'pointer'}}>Download PDF</button>
                </a>
              </div>

            </div>
        </div>
     </div>
     <Footer />
    </>
  )
}

export default Blogs
