import React from 'react';
import './aboutproduct.css';
import Button from '../ButtonComponent/Button';

export default function AboutProduct(props){ 

    return (
        <>
          <div className="sf-aboutproduct-top-section">
            <div className="sf-aboutproduct-container">
                <h4 className='sf-aboutproduct-heading'>Why Choose SnippetFlow?</h4>
                <p className="sf-aboutproduct-description">SnippetFlow provides the flexibility of code-driven data
                    processing while offering out-of-the-box solutions for non-coders. It’s highly customizable,
                    serverless, and capable of scaling to meet any data challenge. With its unique marketplace
                    of pre-built data pipelines, SnippetFlow enables quick data acquisition and transformation
                    without sacrificing control.
                </p>
                <div className="sf-aboutproduct-btn"><Button title={'Book a Demo'} bg={'white'} textColor={'rgb(28, 28, 116)'} /></div>
            </div>
          </div>
        </>
    )
}