import React from 'react';
import './bannertwo.css';

export default function BannerTwo({data}){ 

  const lightGradient = ['white', 'rgb(250, 250, 250)'];
  const darkGradient = ['#080812', '#08083b'];

  const gradient = data.bg == 'dark' ? darkGradient : lightGradient ;

  const gradientStyle = {
    backgroundImage: `linear-gradient(to right, ${gradient[0]}, ${gradient[1]})`
  };

  const darkColor = '#101857'
  const lightColor = 'white'

  const darkDesc = "rgb(87, 87, 87)";
  const lightDesc = "white"
    return (
        <>
          <div className="dc-banner2-top-section" style={gradientStyle}>
            <div className="dc-banner2-container" >
                <h4 className='dc-banner2-heading' style={{color : data.bg == "dark" ? lightColor : darkColor}}>{data.title}</h4>
                <p className="dc-banner2-description" style={{color : data.bg == "dark" ? lightColor : darkDesc}}>{data.description}</p>
                <p className="dc-banner2-description" style={{color : data.bg == "dark" ? lightColor : darkDesc}}>{data.descriptionTwo}</p>
                <p className="dc-banner2-description" style={{color : data.bg == "dark" ? lightColor : darkDesc}}>{data.descriptionFinal}</p>
            </div>
          </div>
        </>
    )
}