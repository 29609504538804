import React from 'react';
import './landingpage.css'; // Importing the CSS file
import landingBg from "../../Images/rainbowbg.png";

const LandingPage = () => {
  return (
    <div className="dc-land-container">
      <div className="dc-land-content">
        <h1 className="dc-land-title">DATAKOB</h1>
        <p className="dc-land-description">
          Helps Data Teams 
        </p>
        <p className="dc-land-text">
          DataKob is designed for data engineers and scientists, ensuring accurate, 
          versioned, and high-quality data for consumers and AI models. Our all-in-one 
          solution combines data modelling, pipelining, validation, and observability, all
          within a user-friendly interface.
        </p>
      </div>
      <div className="dc-land-image">
        <img 
          src={landingBg}
          alt=''
          className="dc-land-img" 
        />
      </div>
    </div>
  );
};

export default LandingPage;
