import Home from './Components/Home/Home';

function Datacob() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default Datacob;
