import React from 'react'
import './news.css';
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import img from '../../Images/News/London Expo.jpg'

const News = () => {
  return (
    <>
     <Navbar />
     <div className="db-news-updates-sect">
       <div className="db-news-updates-head">News and Updates</div>
       <img src={img} alt="news" className='db-news-img' />
     </div>
     <Footer />
    </>
  )
}

export default News