import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App1 from './Components/Dabler/App';
import Snippetflow from './Components/Snippetflow/App';
import Datacob from './Components/Datacob/App';
import Ira from './Components/Ira/Ira';
import AboutDabler from './Components/Dabler/Components/AboutUs/AboutDabler';
import ScrollToTop from './Components/Utils/ScrollToTop';
import Blogs from './Components/Dabler/Components/Blogs/Blogs';
import News from './Components/Dabler/Components/News/News';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<App1 />}/> 
          <Route path="/snippetflow" element={<Snippetflow />}/> 
          <Route path="/dataKob" element={<Datacob />}/> 
          <Route path="/acuber" element={<Ira />}/> 
          <Route path="/about" element={<AboutDabler />}/> 
          <Route path="/blogs" element={<Blogs />}/> 
          <Route path="/news" element={<News />}/> 
          {/* <Route path="*" element={<div>No page found</div>}/>  */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;


const socialLinks = {
  linkedIn : "https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fpublic-profile%2Fsettings%3Ftrk%3Dd_flagship3_profile_self_view_public_profile",
  fb : "https://www.facebook.com/dablerltd",
  ig : "",
  x : "https://twitter.com/Dabler56052710"
}

export {socialLinks}