import React from "react";
import { Link } from "react-router-dom";
import dbcreativecard from './creativeCard.module.css';

export default function CreativeCard(props){

    return (
        <>
          <div className={dbcreativecard["creative-card-wrapper"]}>
              <div className={dbcreativecard["creativecard-data-container"]}>
                  <div className={dbcreativecard["creativecard-data-heading-section"]}>
                    <div className={dbcreativecard["creative-card-head"]}>{props.data.name}</div>
                  </div> 
                  <div className={dbcreativecard["creativecard-data-description-section"]}>
                    <p>{props.data.description}</p>
                   </div>
                  <div className={dbcreativecard["creativecard-data-btn-container"]}>
                    <Link to={props.data.link} className={`${dbcreativecard["creativecard-btn"]} ${dbcreativecard['apg']}`}>
                      More Details
                    </Link>
                  </div>
              </div>
          </div> 
        </> 
    )
}