import React, { useState } from "react";
import './formcontact.css';

export default function FormContact(props){

    const [res, setRes] = useState(false);
    const [formData, setFormData] = useState({firstName: '',lastName: '', companyName: '', email: '', phoneNumber: '', country: '', message: ''});

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value, // Update the specific form field
        });
      };

    // Send the user data to the backend
    const handleSubmit = async (e) => {
        e.preventDefault();
        // const backendUrl = '';
        // const response = await fetch(backendUrl, {
        //     method : "POST",        
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({...formData, about : props? props.about : "dabler" }),
        // })

        // if(response.ok) setRes(true)
        // else setRes(false);

        console.log('Form submitted with:', formData, {about : props? props.about : "dabler"} );
    };

    return (
        <>
          <div className="db-form-com-cont" id="contact-us">
            <div className="db-form-com-wrap">
                <div className="db-form-com-head">Contact Us</div>
                <form onSubmit={handleSubmit} className="db-form-com">
                    <div className="db-form-com-lab-in">
                        <label htmlFor="firstName" className="db-form-com-lab">First Name*</label>
                        <input
                            type="text"
                            id="firstName"
                            placeholder="First Name"
                            className="db-form-com-input"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            maxLength={100}
                            minLength={1}
                            required
                        />
                    </div>

                    <div className="db-form-com-lab-in">
                        <label htmlFor="lastName" className="db-form-com-lab">Last Name*</label>
                        <input
                            type="text"
                            id="lastName"
                            placeholder="Last Name"
                            className="db-form-com-input"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            maxLength={100}
                            minLength={1}
                            required
                        />
                    </div>

                    <div className="db-form-com-lab-in">
                        <label htmlFor="companyName" className="db-form-com-lab">Company Name*</label>
                        <input
                            type="text"
                            id="companyName"
                            placeholder="Company Name"
                            className="db-form-com-input"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            maxLength={100}
                            minLength={1}
                            required
                        />
                    </div>

                    <div className="db-form-com-lab-in">
                        <label htmlFor="email" className="db-form-com-lab">Business Email*</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Business Email"
                            className="db-form-com-input"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            maxLength={100}
                            minLength={1}
                            required
                        />
                    </div>

                    <div className="db-form-com-lab-in">
                        <label htmlFor="phoneNumber" className="db-form-com-lab">Phone Number*</label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            placeholder="Phone Number"
                            className="db-form-com-input"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            maxLength={100}
                            minLength={1}
                            required
                        />
                    </div>

                    <div className="db-form-com-lab-in">
                        <label htmlFor="country" className="db-form-com-lab">Country*</label>
                        <input
                            type="text"
                            id="country"
                            placeholder="Country"
                            className="db-form-com-input"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            maxLength={100}
                            minLength={1}
                            required
                        />
                    </div>

                    <div className="db-form-com-lab-ta">
                        <label htmlFor="message" className="db-form-com-lab">Message*</label>
                        <textarea
                            id="message"
                            className="db-form-com-ta"
                            placeholder="Message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            maxLength={400}
                        />
                    </div>

                    <div className="db-form-com-btn-cont">
                        <button type="submit">Send</button>
                       {/* {res ? 
                            (<div style={{color:"green", fontSize:"0.7rem"}}>We got your message, We'll reach out to shortly!</div>):
                            (<div style={{color:"red", fontSize:"0.7rem"}}>couldnt send your request, please try again!</div>)
                        } */}
                    </div>
                </form>

            </div>
          </div>
        </>
    )
}

