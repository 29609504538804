import React from 'react';
import './iracard.css';
import img1 from '../../Images/PNG/IraCards/discuss.png';
import img2 from '../../Images/PNG/IraCards/empathy.png';
import img3 from '../../Images/PNG/IraCards/text.png';
import img4 from '../../Images/PNG/IraCards/workflow.png';
import img5 from '../../Images/PNG/IraCards/value.png';

const cardData = [
    {img : img1, head : "Objective-focused discussions", desc : "Go beyond query handling with your AI agents. Embrace scenario management powered by generative AI for goal-driven customer interactions"},
    {img : img2, head : "Human-like empathy", desc : "Say No to mechanical responses ! Truly empathize with your customer situations. Generate personalized recommendations and solutions based on their preferences."},
    {img : img3, head : "Gen AI-powered Text Processing", desc : "Empower  the agents with generative AI powered chat summaries, response and tonality suggestions for quicker and effective query resolutions."},
    {img : img4, head : "Flexible workflows", desc : "Real life customer scenarios are dynamic. Adapt to ever changing customer scenarios with our generative AI-infused Dynamic Workflow Generator for customizable, agile responses"},
    {img : img5, head : "Quick turnaround to value", desc : "Quickly launch campaigns with AI-generated contextual templates customized to your goals and user segments. Seamlessly refine tone, brevity, and urgency using our generative AI-driven content engine"},
]





export default function IraCard(){ 
    return (
        <>
          <div className="ira-card-card-container" >
            <div className="ira-card-card-wrapper">

                {cardData.map( data => {return (<div className="ira-card-each-card">
                    <div className="ira-card-img-container"><img src={data.img} alt="img1" className="ira-card-img" /></div>
                        <div className="ira-card-des-container">
                            <div className="ira-card-head">{data.head}</div>
                            <p className="ira-card-text">{data.desc}</p>
                        </div>
                    </div>
                )})}

            </div>
          </div>
        </>
    )
}