import sliderImg1 from '../../Images/SliderImages/officeMeeting2.jpg';
import sliderImg2 from '../../Images/SliderImages/officeMeeting.jpg';
import sliderImg3 from '../../Images/SliderImages/slider1.jpg';


// const sliderData = [
//     {sliderImg : sliderImg1, title : 'Where purposefull innovation meets reliable technologies', description : 'Respect, entrepreneurship, freedom, loyalty, empathy, care, tenacity, integrity, oneness, nimbleness, simplicity are the values Reflections upholds in its journey to be one of the fastest growing technology services companies.'},
//     {sliderImg : sliderImg2, title : 'Generational AI tool', description : 'Our customer engagements are long term where we share their vision and own their goals like our own. We are completely invested in every engagement in terms of not only money, but time, energy and mindshare.'},
//     {sliderImg : sliderImg3, title : 'Cloud System', description : 'Transform the way how your business work'}
// ]

const sliderData = [
    {   sliderImg : sliderImg1,
        head : "Flexible Data Platforms",
        description : [
            'Your data, Instant AI-driven insights.',
            'Efficiently detect and fix data issues',
            'Effortless data transfer from any source to any destination with Data contracts'
        ]
    },
    {   sliderImg : sliderImg2,
        head : "Data Processing with Flexibility and Scalability",
        description : [
            'Code-First solution',
            'Serverless and Infinite Scale platforms',
            'Marketplace for data pipelines',
            'Cost efficient for cloud solutions '
        ]
    },
    {   sliderImg : sliderImg3,
        head : "Generative AI, and Large Language Models",
        description : [
            'Cutting-edge solutions to optimise data processes and harness the power of AI-driven insights',
            'Empowering organisations to transform and make swift, intelligent decisions using advanced technology'
        ]
    }
]

export default sliderData;





// Flexible data platforms
// Your data. Instant AI-driven insights.
// Efficiently detect and fix data issues.
// Effortless data transfer from any source to any destination with Data contracts



// Empower  Data Processing with Flexibility and Scalability
 
// Code-First solution
// Serverless and Infinite Scale platforms
// Marketplace for data pipelines
// Cost efficient for cloud solutions 
 


// Generative AI, and Large Language Models

// 1. Cutting-edge solutions to optimise data processes and harness the power of AI-driven insights. 

// 2. Empowering organisations to transform and make swift, intelligent decisions using advanced technology.
 